
import { defineComponent, ref, computed } from 'vue'
import { UserData } from '@/pinia/user/types'
import useAuth from '@/use/useAuth'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'NavbarWithoutMenuDrawer',
	setup() {
		//
		const menuList = ref([])
		const isDrawerOpen = ref(false)
		const onMenuClick = () => {
			isDrawerOpen.value = true
		}
		const userStore = useUserStore()
		const { signOut } = useAuth()
		const user = computed<UserData>(() => userStore.userData)

		return {
			onMenuClick,
			isDrawerOpen,
			menuList,
			user,
			signOut,
		}
	}
})
