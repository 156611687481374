
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import useAuth from '@/use/useAuth'
import { UserData } from '@/pinia/user/types'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'NavbarWithoutMenu',
	setup () {
		const route = useRoute()
		const userStore = useUserStore()
		const { signOut } = useAuth()
		const user = computed<UserData>(() => userStore.userData)

		return {
			route,
			user,
			signOut,
		}	
	}
})
