import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55555e7d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_ctx.user)
    ? (_openBlock(), _createBlock(_component_el_menu, {
        key: 0,
        "default-active": '1',
        mode: "horizontal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_menu_item, { style: {"border-bottom":"none"} }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"6rem","height":"auto"},
                src: require('@/assets/order-plus-with-word.png')
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_sub_menu, {
            index: "10",
            class: "profile-menu"
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_image, {
                  src: _ctx.user?.photoURL,
                  fit: "contain",
                  class: "custom-image"
                }, null, 8, ["src"]),
                _createElementVNode("span", null, _toDisplayString(_ctx.user.displayName), 1)
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_menu_item, {
                onClick: _ctx.signOut,
                index: "12",
                style: {"color":"red"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ออกจากระบบ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}